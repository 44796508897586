





























































































import { apiAlipayShopVersionList, apiAlipayVersionList } from '@/api/shop'
import { Component, Prop, Vue } from 'vue-property-decorator'
import lsPagination from '@/components/ls-pagination.vue'
import LsDialog from '@/components/ls-dialog.vue'
import { RequestPaging } from '@/utils/util'
import { apiOtherLists } from '@/api/order'
// import lsEdit from './edit.vue'
@Component({
  components: {
    lsPagination,
    LsDialog
  }
})
export default class ModulesList extends Vue {
  /** S Data **/
  $refs!: { editOrAdd: any };
  apiAlipayShopVersionList = apiAlipayShopVersionList;

  pager: RequestPaging = new RequestPaging();

  searchObj = {
    page_size: 15,
    page_no: 1,
    versionId: null
  };

  form: any = {
    appVersion: '',
    versionDescription: ''
  };

  type = '';

  versionList=[]

  getShopLists (): void {
    this.pager.request({
      callback: apiAlipayShopVersionList,
      params: {
        ...this.searchObj
      }
    })
  }

  reset () {
    this.searchObj = {
      page_size: 15,
      page_no: 1,
      versionId: null
    }
    this.getShopLists()
  }

  getShopVerList () {
    apiAlipayVersionList({
      page_size: 15,
      page_no: 1
    }).then(res => {
      console.log(res)
      this.versionList = res.lists
    })
  }

  handleAdd () {
    this.form = {
      appVersion: '',
      versionDescription: ''
    }
    this.type = 'add'
    console.log(this.$refs.editOrAdd, 'this.$refs.editOrAdd')
    // this.$refs.editOrAdd.openDialog()
    this.$refs.editOrAdd.openDialog()
  }

  /** S ods **/
  created () {
    this.getShopLists()
    this.getShopVerList()
  }
}
